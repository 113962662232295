import Decimal from "decimal.js";
import {
  BuildingsQuery,
  GraphQLAirConditionerType,
  GraphQLEarthquakeResistantStructure,
  GraphQLElevatorAccessControl,
  GraphQLJanitor,
  GraphQLSelfLocking,
} from "../graphql";
import { formatFloor } from "./AskingFormatters";

export const formatBuildingFloors = (floors: string[]) => {
  const decimalConstMinus1 = new Decimal("-1"),
    decimalConst1 = new Decimal("1");
  const isConsecutiveFloor = (floor1: Decimal, floor2: Decimal) =>
    (floor1.eq(decimalConst1) && floor2.eq(decimalConstMinus1)) ||
    (floor1.eq(decimalConstMinus1) && floor2.eq(decimalConst1)) ||
    floor1.sub(floor2).abs().lessThanOrEqualTo(decimalConst1);
  const sortedFloors = floors
    .map((floor) => new Decimal(floor))
    .sort((floorA, floorB) => floorA.sub(floorB).toNumber());
  const formattedFloors = sortedFloors.reduce(
    (acc, floor) => {
      if (acc.length === 0) {
        acc.push([floor, floor]);
        return acc;
      }
      const [floorStart, floorEnd] = acc[acc.length - 1];
      acc.pop();
      if (isConsecutiveFloor(floorEnd, floor)) {
        acc.push([floorStart, floor]);
      } else {
        acc.push([floorStart, floorEnd], [floor, floor]);
      }
      return acc;
    },
    [] as [Decimal, Decimal][],
  );
  return formattedFloors
    .map(([floorStart, floorEnd]) =>
      floorStart.eq(floorEnd)
        ? `${formatFloor(floorStart.toString())}F`
        : `${formatFloor(floorStart.toString())}F〜${formatFloor(
            floorEnd.toString(),
          )}F`,
    )
    .join(", ");
};

export const formatBuildingAddress = (building: {
  block?: string | null;
  prefecture: { name: string };
  city: { name: string };
  town: string;
}) => {
  const block = building.block ?? "";
  return `${building.prefecture.name}${building.city.name}${building.town}${block}`;
};

export const formatBuildingOldName = (
  oldName: BuildingsQuery["buildings"][0]["oldNames"][0],
) => {
  return `${oldName.name} (〜${oldName.renamedYear}/${oldName.renamedMonth})`;
};

// 緯度を０埋めして固定小数点表示として返す
export const formatBuildingLatitude = (latitude?: string) => {
  if (!latitude) {
    return;
  }

  const value = Number.parseFloat(latitude);
  if (Number.isNaN(value)) {
    return;
  }

  // 桁数は仮に小数点以下6桁精度で表示している。（経度の方が整数部が１桁多い）
  return value.toFixed(6);
};

// 経度を０埋めして固定小数点表示として返す
export const formatBuildingLongitude = (longitude?: string) => {
  if (!longitude) {
    return;
  }

  const value = Number.parseFloat(longitude);
  if (Number.isNaN(value)) {
    return;
  }

  // 桁数は仮に小数点以下6桁精度で表示している。（経度の方が整数部が１桁多い）
  return value.toFixed(6);
};

export const formatEarthquakeResistantStructure = (
  earthquakeResistantStructure: BuildingsQuery["buildings"][0]["earthquakeResistantStructure"],
) => {
  switch (earthquakeResistantStructure) {
    case GraphQLEarthquakeResistantStructure.NewStandard: {
      return "新耐震基準";
    }
    case GraphQLEarthquakeResistantStructure.OldStandardReinforced: {
      return "旧耐震基準（補強工事済み）";
    }
    case GraphQLEarthquakeResistantStructure.OldStandard: {
      return "旧耐震基準";
    }
    case GraphQLEarthquakeResistantStructure.Unknown: {
      return "不明";
    }
    default: {
      const nv: never = earthquakeResistantStructure;
      throw new Error(`undefined option ${nv}`);
    }
  }
};

export const formatBuildingJanitor = (
  janitor?: BuildingsQuery["buildings"][0]["janitor"],
) => {
  if (!janitor) {
    return;
  }
  switch (janitor) {
    case GraphQLJanitor.Stationed: {
      return "常駐";
    }
    case GraphQLJanitor.None: {
      return "なし";
    }
    default: {
      const nv: never = janitor;
      throw new Error(`undefined option ${nv}`);
    }
  }
};

export const formatSelfLocking = (
  selfLocking?: BuildingsQuery["buildings"][0]["selfLocking"],
) => {
  if (!selfLocking) {
    return;
  }
  switch (selfLocking) {
    case GraphQLSelfLocking.Equipped: {
      return "あり";
    }
    case GraphQLSelfLocking.NotEquipped: {
      return "なし";
    }
    case GraphQLSelfLocking.NightOnly: {
      return "夜間";
    }
    default: {
      const nv: never = selfLocking;
      throw new Error(`undefined option ${nv}`);
    }
  }
};

export const formatElevators = (
  elevators?: BuildingsQuery["buildings"][0]["elevators"],
) => {
  if (!elevators) {
    return;
  }

  const options = [] as string[];
  if (elevators.capacity) {
    options.push(`${elevators.capacity}人乗`);
  }

  if (elevators.accessControl) {
    switch (elevators.accessControl) {
      case GraphQLElevatorAccessControl.Equipped: {
        options.push("不停止制御装置あり");
        break;
      }
      case GraphQLElevatorAccessControl.NotEquipped: {
        options.push("不停止制御装置なし");
        break;
      }
      default: {
        const nv: never = elevators.accessControl;
        throw new Error(`undefined option: ${nv}`);
      }
    }
  }

  if (options.length > 0) {
    return `${elevators.num}台（${options.join("・")}）`;
  } else {
    return `${elevators.num}台`;
  }
};

export const formatParkingLot = (
  parkingLotTotal?: number,
  parkingLotVacant?: number,
) => {
  if (parkingLotTotal === undefined && parkingLotVacant === undefined) {
    return;
  } else if (parkingLotTotal === undefined && parkingLotVacant !== undefined) {
    if (parkingLotVacant === 0) {
      return `空き無し`;
    } else return `空き${parkingLotVacant}台`;
  } else if (parkingLotTotal === 0) {
    return `無し`;
  } else if (parkingLotTotal !== undefined && parkingLotVacant === undefined) {
    return `${parkingLotTotal}台`;
  } else if (parkingLotVacant === 0) {
    return `${parkingLotTotal}台（空き無し）`;
  } else {
    return `${parkingLotTotal}台（空き${parkingLotVacant}台）`;
  }
};

export const formatAirConditioner = (
  airConditioner?: BuildingsQuery["buildings"][0]["airConditioner"],
) => {
  if (!airConditioner) {
    return;
  }
  switch (airConditioner.airConditionerType) {
    case GraphQLAirConditionerType.Individual: {
      return "個別";
    }
    case GraphQLAirConditionerType.Combined: {
      return "セントラル+個別";
    }
    case GraphQLAirConditionerType.Central: {
      return "セントラル";
    }
    case GraphQLAirConditionerType.Other: {
      return airConditioner.airConditionerText ?? "";
    }
    default: {
      const nv: never = airConditioner.airConditionerType;
      throw new Error(`undefined option: ${nv}`);
    }
  }
};

export const formatPowerCapacity = (
  powerCapacity?: BuildingsQuery["buildings"][0]["powerCapacity"],
  powerCapacitySideNote?: BuildingsQuery["buildings"][0]["powerCapacitySideNote"],
) => {
  if (!powerCapacity && !powerCapacitySideNote) {
    return;
  }
  if (powerCapacity && !powerCapacitySideNote) {
    return `${powerCapacity}VA/㎡`;
  }
  if (!powerCapacity && powerCapacitySideNote) {
    return powerCapacitySideNote;
  }
  return `${powerCapacity}VA/㎡（${powerCapacitySideNote}）`;
};
